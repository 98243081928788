/* eslint-disable no-unused-vars */
import { mapSingleTeamKeys } from "components/Matches/helper";
import {
  playerAttributesCaseMap,
  basicPlayerInfoKeys,
  playerAttributeKeys,
  cricketAttributeKeys,
  allowedRoles,
} from "components/Players/PlayerConstants";
import { SPORTS } from "components/Common Components/Sports/SportsConstants"
import { ENV, apiEndpoints, baseUrl } from "config/apiEndpoints";
import { parseISO } from "date-fns";
import { GET, POST } from "services";
import { BULK_UPLOAD_CHUNK_SIZE, PLAYER_PAGE_SIZE } from "utils/constants";

export const getPlayersListPaginated = async (reqData: any) => {
  if (!Object.keys(reqData).length) {
    reqData = {
      page: 1,
      pageSize: PLAYER_PAGE_SIZE,
    };
  }

  const result = await POST(
    baseUrl[ENV] + apiEndpoints.playersListPaginated,
    reqData
  );
  return result;
};

export const createPlayer = async (playerData: any) => {
  const result = await POST(
    baseUrl[ENV] + apiEndpoints.createPlayer,
    playerData
  );
  return result;
};

export const getPlayerDetailsById = async (playerId: string) => {
  const params = { playerId: playerId };
  const result = await GET(baseUrl[ENV] + apiEndpoints.getPlayerById, params);
  return result;
};

export const editPlayer = async (playerData: any) => {
  const result = await POST(
    baseUrl[ENV] + apiEndpoints.createPlayer,
    playerData
  );
  return result;
};

const getSportAttributes = (sport:string, playerData: any) => {
  const attributes = playerData.playerAttributes?.filter(
    (attr: any) => attr.sportsCode === sport
  ).map((attr: any)=>{
    return {
      ...attr,
      fieldName: `${sport.toLowerCase()}_${attr.attributeName}`
    }
  })
  return attributes;
}

export const formatGetPlayerData = (playerData: any) => {
  const extraDataKeys = ["createdAt", "updatedAt", "id"];
  for (let key of extraDataKeys) {
    delete playerData[key];
  }

  playerData["dob"] = parseISO(playerData["dob"]);

  const stateObj = {
    id: playerData.state_id || '',
    label: playerData.state || '',
  }
  const cityObj = {
    id: playerData.city_id || '',
    label : playerData.city || '',
  }

  playerData.state = stateObj;
  playerData.city = cityObj;

  if (playerData.teams?.length) {
    const playerSports = playerData.teams.reduce(
      (acc:any[], team:any) => {
        return acc.concat(team?.sports_codes).filter(
          (value, index, arr) => arr.indexOf(value) === index
        )
      },
      []
    );

    let playerSportsConfig = {};
    const playerSportsOptions: any[] = [];
    playerSports.forEach((sport: string)=>{
      playerSportsConfig = {
        ...playerSportsConfig,
        [sport] : {
          teams: playerData.teams?.map((team: any) => {
            if (team.sports_codes?.includes(sport))    
              return mapSingleTeamKeys(team);
            }).filter((value: any) => value !== undefined),
          attributes: getSportAttributes(sport, playerData)
        }
      };
      playerSportsOptions.push({
        id: sport.toUpperCase(),
        label: sport.charAt(0).toUpperCase() + sport.slice(1),
      });
    });

    playerData.playerSports = playerSports;
    playerData.playerSportsConfig = playerSportsConfig;
    playerData.playerSportsOptions = playerSportsOptions;
  }

  return playerData;
};

export const formatPostPlayerData = (playerData: any) => {
  const playerDetails: any = {};
  const playerAttributes: any = [];
  const teams: any = [];
  const squads: any[] = [];

  playerDetails["name"] = playerData["name"];
  playerDetails["shortName"] = playerData["short_name"];
  playerDetails["gender"] = playerData["gender"];
  playerDetails["imageUrl"] = playerData["image_url"];
  playerDetails["isActive"] = playerData["is_active"];
  playerDetails["age"] = playerData["age"];
  playerDetails["dob"] = playerData["dob"];
  playerDetails["mobile"] = playerData["mobile"];
  playerDetails["state_id"] = playerData["state"]?.id;
  playerDetails["state"] = playerData["state"]?.label;
  playerDetails["city_id"] = playerData["city"]?.id;
  playerDetails["city"] = playerData["city"]?.label;
  playerDetails["facebook_handle"] = playerData["facebook_handle"];
  playerDetails["instagram_handle"] = playerData["instagram_handle"];
  playerDetails["linkedin_handle"] = playerData["linkedin_handle"];
  playerDetails["idol"] = playerData["idol"];

  if (playerData["player_id"]) {
    playerDetails["playerId"] = playerData["player_id"];
  }

  playerData.sports.forEach((sport: any)=>{
    const attributes = SPORTS.find((s)=> s.value === sport.id)?.attributes || [];
    attributes.forEach((attr)=>{
      const dataPoint = {
        attributeName: attr,
        attributeValue: playerData[`${sport.id.toLowerCase()}_${attr}`],
        sportsCode: sport.id,
      };
      playerAttributes.push(dataPoint);
    });
    
    if(playerData[`${sport.id.toLowerCase()}_teams`].length){
      playerData[`${sport.id.toLowerCase()}_teams`].forEach(
        (team: any) =>{
          const squad = squads.find((t) => t.teamId === team?.id)
          if(squad){
            squad.sportsCodes.push(sport.id)
          }else{
            squads.push({
              teamId: team?.id,
              sportsCodes: [sport.id]
            })
          }
        })
    }
  });

  return {
    playerDetails,
    playerAttributes,
    squads,
  };
};

const formatBulkPlayerData = (playerDataAll: any) => {
  const result: any[] = playerDataAll.map((playerData: any) => {
    
    const playerSports = SPORTS.reduce((
      (acc: any, sport: any ) => {
        sport.attributes.forEach((attr: any)=>{
          if(playerData[`${sport.value.toLowerCase()}_${attr}`] !== ""){
            acc.push({
              id: sport.value,
              label: sport.label,
            })
          }
        });
        return acc.filter((value: any , index:number, self: any[]) =>
          index === self.findIndex((t) => (
            t.id === value.id && t.label === value.label
          ))
        )
      }
    ),[]);
    playerData.sports = playerSports;
    
    playerSports.forEach((sport: any )=> {
      const sportTeams = {id: playerData[`${sport.id.toLowerCase()}_teams`]}
      playerData[`${sport.id.toLowerCase()}_teams`] = [sportTeams];
    })

    const city = {id : playerData.city_id || "", label: playerData.city};
    playerData.city = {...city};
    const formattedPlayerData = formatPostPlayerData(playerData);
    formattedPlayerData.playerDetails.short_name = formattedPlayerData.playerDetails.shortName;
    formattedPlayerData.playerDetails.image_url = formattedPlayerData.playerDetails.imageUrl;

    delete formattedPlayerData.playerDetails.shortName;
    delete formattedPlayerData.playerDetails.imageUrl;
    return formattedPlayerData
  });

  return result;
};

export const createBulkPlayers = async (data: any) => {
  // console.log("data from recieved in createBulkPlayers function", data);

  let formattedData: any[] = formatBulkPlayerData(data);
  // console.log("formatted data: ", formattedData);

  const chunks = [];
  for (let i = 0; i < formattedData.length; i += BULK_UPLOAD_CHUNK_SIZE) {
    chunks.push(formattedData.slice(i, i + BULK_UPLOAD_CHUNK_SIZE));
  }
  // console.log("chunks created: ", chunks);
  for (const chunk of chunks) {
    try {
      await POST(baseUrl[ENV] + apiEndpoints.createBulkPlayers, chunk);
    } catch (err) {
      console.error("Error sending chunk:", err);
      throw err;
    }
  }
};
