import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";

import { TextFieldInputStyles } from "./FormUtils/FormStyles";
import styles from "./styles.module.css";

export const FormInputText = (props: any) => {
  const { name, label, placeholder, disabled, rules, required, maxLength } =
    props;

  const { control } = useFormContext();

  const inputProps: any = {"data-testid":name};
  if (maxLength) inputProps["maxLength"] = maxLength;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      rules={rules}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => (
        <>
          <label className={styles["form-label"]}>
            {label ? label : <>&nbsp;</>}
            {required ? (
              <span className={styles["required-symbol"]}>*</span>
            ) : (
              <></>
            )}
          </label>
          <TextField
            size="small"
            error={!!error}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            disabled={disabled}
            sx={TextFieldInputStyles}
            inputProps={inputProps}
            // data-testid={name}
          />
          <div className={styles["error-msg"]}>
            {error ? error.message : null}
          </div>
        </>
      )}
    />
  );
};
